<template>
  <v-container fluid>
    <v-row class="justify-center">
      <v-card v-if="users">
        <v-data-table :headers="headers" :items="users" class="elevation-1">
          <template v-slot:top>
            <v-toolbar dense flat class="teal" dark>
              <v-toolbar-title>Usuarios </v-toolbar-title>

              <v-spacer></v-spacer>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color=""
                    v-on="on"
                    v-bind="attrs"
                    class="mt-2 mb-2"
                    icon
                    x-large
                    @click="dialog = true"
                  >
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                </template>
                Cadastrar novo usuario
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
          </template>
          <template v-slot:item.on_duty="{ item }">
            <v-switch
              inset
              v-model="item.on_duty"
              @change="changeStatus(item.id)"
            ></v-switch>
          </template>
        </v-data-table>
      </v-card>
      <v-dialog
        class="justify-center"
        v-model="dialog"
        v-if="dialog"
        max-width="600px"
        transition="dialog-transition"
      >
        <newUser
          v-on:update-users="getUsers()"
          v-on:close-dialog="dialog = false"
        />
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import newUser from "./new";

export default {
  name: "Users",
  components: {
    newUser
  },
  data: () => {
    return {
      dialog: false,
      users: [],
      headers: [
        { text: "ID", value: "id" },
        { text: "Nome", value: "name" },
        { text: "Perfil", value: "role_name" },
        { text: "Plantonista", value: "on_duty" }
      ],

      employe: {
        cpf: "",
        senha: "",
        old_senha: "",
        fk_role: ""
      }
    };
  },
  created() {
    this.getUsers();
  },
  methods: {
    async getUsers() {
      try {
        const response = await this.$http.get("/user");
        this.users = response.data;
      } catch (error) {
        this.$toast.error("Erro ao listar usuarios!");
      }
    },
    async changeStatus(id) {
      try {
        const response = await this.$http.put(`/user/${id}/onduty`);
        if (response.data.on_duty) {
          this.$toast.success("Plantonista habilitado.");
        } else {
          this.$toast.info("Plantonista desabilitado.");
        }
      } catch (error) {
        this.$toast.error("Erro ao listar usuarios!");
      }
    }
  }
};
</script>

<style></style>
