<template>
  <v-card v-if="employees">
    <v-toolbar color="teal" dark>
      <v-toolbar-title>Cadastro de usuario</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon v-on:click="$emit('close-dialog')"
        ><v-icon arge>mdi-close-circle</v-icon></v-btn
      >
    </v-toolbar>

    <v-card-text class="mt-10">
      <v-form v-model="valid" ref="form">
        <v-autocomplete
          v-model="employe"
          :items="employees"
          label="Funcionário"
          item-text="name"
          outlined
          return-object
          @change="passwordGenerate()"
        >
        </v-autocomplete>
        <v-select
          :items="permissions"
          label="Permissão"
          item-text="name"
          item-value="id"
          v-model="roleSelected"
          outlined
        ></v-select>
        <v-alert v-if="employe" color="primary" outlined :value="true">
          Senha: <strong> {{ password }} </strong>
        </v-alert>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="create()"> Cadastrar usuario </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data: () => {
    return {
      e1: 1,
      employees: null,
      searchInput: null,
      password: null,
      valid: false,
      roleSelected: null,
      idteam: null,
      employe: null,
      user: null,
      permissions: [
        { id: 1, name: "Suporte" },
        { id: 2, name: "Analista" },
        { id: 3, name: "Coordenador" },
        { id: 4, name: "Administrador" }
      ]
    };
  },
  created() {
    this.getEmployees();
    this.passwordGenerate();
  },
  methods: {
    async create() {
      try {
        this.user = {
          cpf: this.employe.cpf,
          password: this.password,
          fk_role: this.roleSelected
        };
        await this.$http.post("/user", this.user);
        this.$toast.success("Usuario criado com sucesso");
        this.$emit("update-users");
      } catch (error) {
        this.$toast.error("Usuario já cadastrado!");
      }
    },
    passwordGenerate() {
      let length = 8,
        charset = "abcdefghijklmnopqrstuvwxyz123456789",
        passwd = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
        passwd += charset.charAt(Math.floor(Math.random() * n));
      }

      this.password = passwd;
    },
    getColor(status) {
      if (!status) return "success";
      else return "error";
    },
    async getEmployees() {
      const result = await this.$http.get("/employe");
      this.employees = result.data;
    },

    async registerTime() {
      const result = await this.$http.post("/team", this.team);
      this.idteam = result.data.id;
      this.$emit("update-team");
    }
  }
};
</script>

<style></style>
